import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, Stack } from '@mui/material';
import { AxiosError } from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocationList } from '../../../admin/hooks';
import {
    ControlledCheckbox,
    ControlledNumberInput,
    DialogTitleWithClose,
    IBuyer,
    IBuyerForm,
    InUseWarning,
} from '../../../shared';
import { useSaveBuyer } from '../../hooks';
import { buyerFromFormMapper } from '../../mappers';
import { useBuyerSchema } from '../../validators';
import { CustomerDataInputs } from '../customer-data-inputs/customer-data-inputs.component';
import { SelectCustomer } from '../select-customer/select-customer.component';

interface Props {
    open: boolean;
    close: () => void;
    auctionId: string;
    buyer: IBuyer | undefined;
}

export const SaveBuyerDialog: FC<Props> = ({ open, close, auctionId, buyer }) => {
    const { t } = useTranslation();
    const [showUniqueWarning, setShowUniqueWarning] = useState(false);

    const { mutateAsync: saveBuyer, isPending: isSaving } = useSaveBuyer();
    const { data: locations } = useLocationList({});

    const form = useForm<IBuyerForm>({
        resolver: yupResolver(useBuyerSchema()),
        mode: 'onSubmit',
    });
    const watchNewCustomer = form.watch('newCustomer');

    useEffect(() => {
        if (open) {
            buyer
                ? form.reset(buyer)
                : form.reset({
                      buyerNumber: undefined,
                      saveCustomer: {
                          invites: locations?.data?.map((location) => ({
                              location: location.name,
                              wantsInvite: false,
                          })),
                          address: { country: { code: 'BE', label: 'België' } },
                      },
                  });
        }
    }, [form, open, buyer, locations]);

    useEffect(() => {
        if (watchNewCustomer) {
            form.setValue('customer', undefined);
        }
    }, [form, watchNewCustomer]);

    const onSubmit = async (item: IBuyerForm) => {
        try {
            await saveBuyer({ id: buyer?.id, item: buyerFromFormMapper(item, auctionId) });
            close();
        } catch (err: unknown) {
            if ((err as AxiosError)?.response?.status === 400) {
                return setShowUniqueWarning(true);
            }
            throw err;
        }
    };

    return (
        <Dialog open={open} onClose={close} fullWidth maxWidth="lg">
            <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} noValidate autoComplete="off">
                    <DialogTitleWithClose onClose={close}>
                        {buyer ? t('editBuyer') : t('newBuyer')}
                    </DialogTitleWithClose>
                    <DialogContent>
                        <Stack direction="column" spacing={2} mt={2}>
                            <SelectCustomer disabled={watchNewCustomer} />
                            <ControlledCheckbox label={t('newCustomer')} name="newCustomer" />
                            <CustomerDataInputs disabled={!watchNewCustomer} name="saveCustomer" />
                            <ControlledNumberInput
                                name="buyerNumber"
                                label={t('buyerNumber')}
                                required
                                decimalScale={0}
                            />
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button type="submit" variant="contained" color="primary" disabled={isSaving}>
                            {t('save')}
                        </Button>
                        <Button onClick={close} color="secondary" disabled={isSaving}>
                            {t('cancel')}
                        </Button>
                    </DialogActions>
                </form>
            </FormProvider>
            <InUseWarning
                show={showUniqueWarning}
                onClose={() => setShowUniqueWarning(false)}
                text={t('uniqueBuyerNumberWarning')}
            />
        </Dialog>
    );
};
