import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { boolean, mixed, number, object } from 'yup';
import { ICustomer } from '../../shared';
import { useCustomerSchema } from './customer.validator';

export function useBuyerSchema() {
    const { t } = useTranslation();
    const customerSchema = useCustomerSchema();

    return useMemo(
        () =>
            object().shape({
                buyerNumber: number().min(0).typeError(t('invalidNumber')).required(),
                customer: mixed<ICustomer>()
                    .nullable()
                    .when('newCustomer', {
                        is: false,
                        then: (schema) => schema.required(),
                        otherwise: (schema) => schema.strip(),
                    }),
                newCustomer: boolean().required(),
                saveCustomer: customerSchema.notRequired().when('newCustomer', {
                    is: true,
                    then: (schema) => schema.required(),
                    otherwise: (schema) => schema.strip(),
                }),
            }),
        [t, customerSchema],
    );
}
