import React, { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAbbreviationsMap } from '../../../admin/hooks';
import {
    ControlledAutocomplete,
    ControlledInput,
    ControlledNumberInput,
    ControlledSelect,
    Documentation,
    FormGrid,
    Section,
} from '../../../shared';
import { descriptionReplacementUtil } from '../../utils';
import { SelectAutocompleteArtist } from './select-autocomplete-artist.component';
import { SelectAutocompleteCategories } from './select-autocomplete-categories.component';
import { InputAdornment, MenuItem, Stack } from '@mui/material';

export const ArticleDescriptionInputs: FC = () => {
    const { t } = useTranslation();
    const form = useFormContext();
    const watchArtist = useWatch({ name: 'artist' });
    const watchShortDescription = useWatch({ name: 'shortDescription' });
    const abbreviationsMap = useAbbreviationsMap();

    const onBlurShortDescription = () => {
        if (watchShortDescription) {
            if (!form.getValues('descriptionNl')) {
                form.setValue(
                    'descriptionNl',
                    descriptionReplacementUtil(watchArtist, watchShortDescription, 'nl', abbreviationsMap),
                );
            }
            if (!form.getValues('descriptionEn')) {
                form.setValue(
                    'descriptionEn',
                    descriptionReplacementUtil(watchArtist, watchShortDescription, 'en', abbreviationsMap),
                );
            }
        }
    };

    return (
        <Section title={t('description')}>
            <FormGrid xs={12} sm={6}>
                <Stack spacing={2}>
                    <ControlledInput
                        name="shortDescription"
                        label={t('shortDescription')}
                        multiline
                        rows={3}
                        onBlur={() => onBlurShortDescription()}
                        id="shortDescription"
                    />
                    <ControlledInput name="descriptionNl" label={t('descriptionNl')} multiline rows={5} required />
                    <ControlledInput name="descriptionEn" label={t('descriptionEn')} multiline rows={5} required />
                </Stack>

                <Stack spacing={2}>
                    <SelectAutocompleteArtist />
                    <SelectAutocompleteCategories />

                    <ControlledAutocomplete
                        name="documentation"
                        label={t('documentation')}
                        isOptionEqualToValue={(option, value) => option === value}
                        getOptionLabel={(option) => t(`documentation_options.${option}`)}
                        options={Object.values(Documentation) || []}
                        multiple
                        autoHighlight
                    />

                    <Stack direction="row" spacing={2}>
                        <ControlledNumberInput
                            name="dimensions.widthInCm"
                            label={t('width')}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                            }}
                            sx={{ minWidth: 120 }}
                        />
                        <ControlledNumberInput
                            name="dimensions.heightInCm"
                            label={t('height')}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                            }}
                            sx={{ minWidth: 120 }}
                        />
                        <ControlledNumberInput
                            name="dimensions.depthInCm"
                            label={t('depth')}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                            }}
                            sx={{ minWidth: 120 }}
                        />
                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <ControlledNumberInput name="dimensions.weight" label={t('weight')} />
                        <ControlledSelect name="dimensions.weightUnit" sx={{ minWidth: 65, width: 65 }}>
                            <MenuItem value="gr">gr</MenuItem>
                            <MenuItem value="kg">kg</MenuItem>
                        </ControlledSelect>
                    </Stack>
                </Stack>
            </FormGrid>
        </Section>
    );
};
