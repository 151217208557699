import { IBuyerForm, ISaveBuyer } from '../../shared';
import { customerFromFormMapper } from './customer.mapper';

export const buyerFromFormMapper = (item: IBuyerForm, auctionId: string): ISaveBuyer => {
    return {
        ...item,
        auctionId,
        customerId: item.customer?.id,
        saveCustomer: item.saveCustomer && customerFromFormMapper(item.saveCustomer),
    };
};
