import { Delete } from '@mui/icons-material';
import { IconButton, MenuItem } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactType, ControlledInput, ControlledSelect, FormGrid } from '../../../shared';
import { useWatch } from 'react-hook-form';

interface Props {
    index: number;
    remove: (index: number) => void;
    prefixName: string;
}

export const ContactDataInputs: FC<Props> = ({ index, remove, prefixName }) => {
    const { t } = useTranslation();
    const watchType = useWatch({ name: `contactDetails.[${index}].type` });

    return (
        <FormGrid xs={12} md="auto">
            <ControlledSelect
                name={`${prefixName}contactDetails.[${index}].type`}
                label={t('type')}
                required
                sx={{ minWidth: 200 }}
            >
                {Object.values(ContactType).map((type) => (
                    <MenuItem key={type} value={type}>
                        {t(type.toLowerCase())}
                    </MenuItem>
                ))}
            </ControlledSelect>
            <ControlledInput
                name={`${prefixName}contactDetails.[${index}].data`}
                label={watchType === ContactType.Phone ? t('phone') : t('email')}
                required
                sx={{ minWidth: 400 }}
            />
            <ControlledInput
                name={`${prefixName}contactDetails.[${index}].comment`}
                label={t('comment')}
                sx={{ minWidth: 630 }}
            />
            <IconButton onClick={() => remove(index)}>
                <Delete />
            </IconButton>
        </FormGrid>
    );
};
